@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Replace deprecated color-adjust with print-color-adjust */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

/* Add any custom styles below */
